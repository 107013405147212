.block {

  .component {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .disable-text-transform {
      text-transform: none !important;
    }

    .content-block {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      //text-align: center;

      .value-display {
        color: var(--theme-primary-color);
        text-transform: uppercase;
        font-weight: 600;
        font-size: 4rem;
        text-align: center;
        letter-spacing: 0.05rem;
      }

      img {
        height: 12em;
        width: auto;
        margin-bottom:8px;
        //margin: 0 auto;
      }

    }

    hr {
      border: none;
      width: 60%;
      height: 5px;
      background-color: var(--theme-highlight-color);
      margin: 0 auto;
    }

    .title {
      //flex-grow: 1;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 0;
      height: 50px;
      overflow: visible;

      h2 {
        text-transform: uppercase;
        margin: 0;
        font-weight: 400;
      }
    }
  }
}

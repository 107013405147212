.theme {
  width: 100vw;
  height: 100vh;
  position: relative;

  &.theme-sos-primary-school {
    --theme-primary-color: #EF7F22;
    --theme-component-color: rgba(255, 255, 255, 0.9);
    //--theme-highlight-color: rgba(193, 227, 224, 0.75);
    --theme-highlight-color: rgba(2, 136, 128, 0.75);
    --theme-highlight-text-color: currentColor;
    --theme-component-border-radius: 1em;
  }

  &.theme-sos-secondary-school {
    --theme-primary-color: #EF7F22;
    --theme-component-color: rgba(255, 255, 255, 0.9);
    --theme-highlight-color: rgba(2, 136, 128, 0.75);
    --theme-highlight-text-color: currentColor;
    --theme-component-border-radius: 1em;
  }

  &.theme-spo-general {
    --theme-primary-color: #F16521;
    --theme-component-color: rgba(255, 255, 255, 0.9);
    --theme-highlight-color: rgba(0, 70, 116, 0.85);
    --theme-highlight-text-color: #fff;
    --theme-component-border-radius: 1em;
  }

  &.theme-ozio-general {
    --theme-primary-color: #600E33;
    --theme-component-color: rgba(255, 255, 255, 0.9);
    --theme-highlight-color: rgba(96, 14, 51, 0.85);/*rgba(138, 211, 220, 0.85);*/
    --theme-highlight-text-color: #fff;
    --theme-component-border-radius: 1em;
  }
}

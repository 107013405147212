

@font-face {
  font-family: 'galano_grotesque_alt';
  src: url('./assets/fonts/galano_grotesque_alt_bold.woff2') format('woff2'),
  url('./assets/fonts/galano_grotesque_alt_bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}
@font-face {
  font-family: 'galano_grotesque_alt';
  src: url('./assets/fonts/galano_grotesque_alt_italic.woff2') format('woff2'),
  url('./assets/fonts/galano_grotesque_alt_italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;

}
@font-face {
  font-family: 'galano_grotesque_alt';
  src: url('./assets/fonts/galano_grotesque_alt_light.woff2') format('woff2'),
  url('./assets/fonts/galano_grotesque_alt_light.woff') format('woff');
  font-weight: 200;
  font-style: normal;

}
@font-face {
  font-family: 'galano_grotesque_alt';
  src: url('./assets/fonts/galano_grotesque_alt.woff2') format('woff2'),
  url('./assets/fonts/galano_grotesque_alt.woff') format('woff');
  font-weight: 400;
  font-style: normal;

}

body {
  margin: 0;
  //padding: 10px;
  font-family: "galano_grotesque_alt", "Helvetica Neue", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root .theme {
  height: 100vh;
  width: 100vw;
}

html, body, .grid-container { height: 100%; margin: 0; }

p {
  margin: 0;
  padding: 0;
}

#widget {
  height: 100vh;
  display: flex; // make us of Flexbox
  align-items: center; // does vertically center the desired content
  justify-content: center; // horizontally centers single line items
  text-align: center; // optional, but helps horizontally center text that breaks into multiple lines

  > p {
    font-size: 3em;
    color: blue;
  }

  .theme {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
